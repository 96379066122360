/**
 * eTMF API
 */



/**
 * This is a generic Authenticated API
**/
export default (url, options) => {
	const config = useRuntimeConfig()
	const { authToken, emulatedUserId, logout } = useAuth()


	const headers = options?.headers ?? {}

	if (authToken.value) {
		headers['Authorization'] = `Bearer ${authToken.value}`
	}

	if (emulatedUserId.value) {
		headers['isEmulation'] = emulatedUserId.value
	}

	if (options?.debug) {
		headers['x-query-debug'] = true
	}

	if (options?.filters) {
		headers['x-filters'] = JSON.stringify(options.filters)
	}

	if (options?.groupBy) {
		headers['x-group-by'] = options.groupBy
	}

	if (options?.pagination)  {
		headers['x-pagination'] = JSON.stringify(options.pagination)
	}


	const fn = options?.raw ? $fetch.raw : $fetch
	
	return fn(`${config.public.baseURL}/${url}`, {
		...options,
		headers,
		onResponseError({ response	 }) {
			const route = useRoute()

			if (response.status === 401) { // Unauthorized
				const whiteList = ['/login', '/create-pass', '/forgot-pass', '/confirm-forgot-pass']
				if (!whiteList.find(path => {
					if (path === route.path) return true
					if (path.test && path.test(route.path)) return true
					return false
				})) {
					logout()
					return navigateTo(`/login?redirect=${route.path}`)
				}
		  }
		}
	})
}
