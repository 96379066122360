<script setup>

import { get, uniqBy } from 'lodash'

// those are global validators
const { $valid, $on, $off, $emit } = useNuxtApp()

const { getList } = useBaseEntity()

const props = defineProps({

  // id: { type: String },

  // field configuration
  field: { type: Object, default: () => ({}) },

  // readonly property
  readonly: { type: [Boolean, Number], default: false },

  //resetValue property
  resetValue: { type: [Boolean, Number], default: false },

  // clearable property
  clearable: { type: [Boolean, Number], default: true },

  // hidden property
  // hidden: { type: [Boolean, Number], default: false },

  // ??
  select: { type: Boolean, default: false },

  // this very field modelValue
  modelValue: { type: [String, Number, Boolean, File, Date, Array, Object], default: undefined },

  selected: { type: [Object] },

  // the whole form item
  item: { type: Object, default: () => ({}) },

  errorMessage: String
})

const { field } = props

// component `size`
const md = props.field.size ?? 6

const data = reactive({ 
  visible: true,
  loading: false,
  autocomplete_search : '',
  items: [],
  focus: false,
  cached_items: [],
  menu_datePicker: false
})

const emit = defineEmits(['update:modelValue', 'reset', 'update:selected'])
// set validators
const rules = computed(() => !props.readonly && field?.validators?.map(v => {
    if (typeof v === 'function') {
      return () => v(props.modelValue, props.item)
    }
    return $valid[v]
  }) || []
)


async function fillListItems () {
  data.loading = true
  data.items = typeof field.list === 'function' ? await field.list(autocomplete_entity, '', props.item) : await props.field.list
  data.loading = false
}

onUnmounted(() => {
  $off('itemUpdated', trackDeps)
})


async function trackDeps(args) {
  // check if changed field is a dependency of this one
  if (args === undefined || !field?.deps?.includes(args.field.key)) {
    return
  }
  
  if (typeof field.visible === 'function') {
    updateVisibility(args)
  }
  
  if (props.readonly && !props.resetValue) return

  if (typeof field?.default === 'function') {
    await updateDefault(args)
  } else {
    emit('update:modelValue', null)
    $emit('itemUpdated', { field })      
  }

  if (['list', 'autocomplete', 'combobox'].includes(field.type) && typeof field?.list === 'function') {
    fillListItems()
  }

  if (['autocomplete','combobox'].includes(field.type) && (field?.filter || typeof field?.list === 'function')) {
      nextTick(() => updateAutocomplete(args))
  }
}

// autocomplete reference is `reference` field or the first part of key
const autocomplete_entity = field.reference || field.key.split('_')[0]


onMounted(async () => {

  // evaluate default value
  if (!props.modelValue && field.default) {
    if (typeof field.default !== 'function') {
      emit('update:modelValue', field.default)
    } else {
      updateDefault()
    }
  }

  if (typeof field.visible === 'function') {
    data.visible = field.visible(props.item)
  } else if (typeof field.visible === 'boolean') {
    data.visible = field.visible
  } else {
    data.visible = true
  }

  if (['autocomplete', 'combobox'].includes(field.type)) {

    // cache values
    if (props.modelValue) { // && !this.field.manual) {
      const filters = field.filter ? field.filter('', props.item) : {}
      const id = field.id || field.key.split('_')[1]
      if (!filters[id]) {
        filters[id] = props.modelValue
      }
      if (typeof field.list === 'function') {
        data.items = data.cached_items = await field.list(autocomplete_entity, '', props.item).catch(e => console.error(e))
      } else {
        data.items = data.cached_items = await getList(autocomplete_entity, filters, { rowsPerPage: 20, sortBy: field?.sortBy }, field.groupby, field.nested)
      }
    } else {
      if (!props.readonly) {
        updateAutocomplete()
      }
    }
  }

  if (field.type === 'list') {
    fillListItems()
  }


  // update default and autocomplete when deps changes
  if (field.deps) {
    $on('itemUpdated', trackDeps)
  }
})


async function updateDefault(args) {
  // calculate and update the a new value
  let value = field.default(props.item, args?.field, args?.selectedItem, field)
  emit('update:modelValue', value)
  // item is updated (run cascade dependencies)
  $emit('itemUpdated', { field, selectedItem: args && args?.selectedItem })
}

// visibility is globally calculated
function updateVisibility(args) {
  data.visible = field.visible(props.item, args?.field, args?.selectedItem)
}

watch(() => data.autocomplete_search, (value, oldvalue) => {
  if (oldvalue && data.focus) {
    updateAutocomplete()
  }
})

/**
 * questo metodo aggiorna gli items della tendina delle autocomplete
 * 
 */
async function updateAutocomplete(args) {
  if (props.readonly) return
  if (args !== undefined) {
        data.cached_items = []
  }

  data.loading = true

  // autocomplete could fill items from a function defined in `list`
  if (typeof field.list === 'function') {
    data.items = await field.list(autocomplete_entity, data.autocomplete_search || '', props.item).catch(e => console.error(e))
  } else {
    const filters = field.filter ? field.filter(data.autocomplete_search || '', props.item) : {}
    if (data.autocomplete_search && typeof field.title !== 'function' && !filters[field.title || 'name']) {
      filters[field.title || 'name'] = '%' + (data.autocomplete_search || '') + "%"
    }

    data.items = await getList(autocomplete_entity, filters, { rowsPerPage: 200, sortBy: field.sortBy }, field.groupby, field.nested)

    if (data.cached_items) {
      data.items = data.items.concat(data.cached_items)
    }
    data.items = uniqBy(data.items, field.id || field.key.split('_')[1])
  }
  data.loading = false
} 

function autocompleteSubtitle(item) {
  if (!field.subtitle) return
  if (typeof field.subtitle === 'string') {
    return get(item, field.subtitle)
  }
  return field.subtitle(item)
}

function autocompleteTitle(item) {
  if (typeof item === 'string') return item
  if (typeof field.title === 'function') {
    return field.title(item)
  } else if (typeof field.title === 'string') {
    return get(item, field.title)
  } else {
    return get(item, 'name')
  }
}

// update modelValue
function updateValue (value) {
  // dall'esterno posso inettare messaggi di errore per questo field
  // ad esempio perche' mi arrivano dal server, quando pero' ne rimodifico
  // il valore il messaggio di errore deve sparire...
  if (props.errorMessage) {
    emit('reset', field.key)
  }

  // cast to a number if it's a number
  if (field.type === 'number') {
    value = Number(value)
    if (isNaN(value)) return
  }

  // v-text-field returns null on clear, set it to empty string
  if (value === null && (field.type === 'text' || field.type === 'number' || !field.type)) {
    value = ''
  }


  let selectedItem
  if (field.type === 'autocomplete' || field.type === 'combobox' || field.type === 'list') {
    if (value !== null && typeof value === "object" && !field.multiple) {
      emit('update:selected', value)
      // data.cached_items = [{...value}] // Array.isArray(value) ? data.items.filter(item => value.includes(get(item, field.id || 'id'))) : []
      value = get(value, field.id || 'id')
    } else if (data.items) {
      selectedItem = data.items.find(item => get(item, field.id || 'id') === value)
      emit('update:selected', selectedItem)
      if (field.multiple) {
        data.cached_items = Array.isArray(value) ? data.items.filter(item => value.includes(get(item, field.id || 'id'))) : []
      } else {
        data.cached_items = value ? data.items.filter(item => value === get(item, field.id || 'id')) : []
      }
    }
  }

  // update model value
  emit('update:modelValue', value)

  // modificando un campo emetto un evento in cui all'interno
  // indico anche:
  // 1. quale campo (cosi' controllo nei consumer dell'evento se e' una dipendenza...)
  // 2. l'intero valore dell'item selezionato se era un'autocomplete/combobox/list (cosi' nelle dipendenze posso usare non solamente il valore (id) della selezione ma l'intero oggetto)
  nextTick(() => { $emit('itemUpdated', { field, selectedItem }) })
  
}
</script>
<template>
  <v-col
    v-show='field.type!=="hidden"'
    v-if='data.visible'
    :md="md"
    sm=12
    :class="select ? 'pa-0 ma-0' : 'pb-0 mb-0'"
  >

  <!-- title: {{ field.title }}<br/>
    label: {{ field.label }}<br/>
    name: {{ field.name }}<br/>
    key: {{ field.key }}<br/>
    render: {{ field.render }}<br/>
    value: {{ modelValue }}<br/> -->
  <v-alert :type='field?.variant ?? "warning"' variant="tonal" v-if="field.type === 'alert'">{{ modelValue }}</v-alert>

  
  <!-- DEFAULT TEXT FIELD -->
  <v-text-field
    v-if="field.type === 'date' || field.type === 'text' || field.type === 'number' || !field.type"
    :id="`field-${field.key}`"
    :type="field.type"
    :hide-details="select"
    :disabled="readonly"
    :model-value="props.modelValue"
    :rules="rules"
    :label="field.label"
    autocomplete="off"
    :error-messages="errorMessage ? [errorMessage] : undefined"
    persistent-hint
    :hint='typeof field.hint === "function" ? field.hint(item) : field.hint'
    :clearable="clearable && !readonly"
    @update:model-value="updateValue" />
  
  <v-select
    v-if="field.type === 'list'"
    :id="`field-${field.key}`"
    :menu-props="{ width: '273px' }"
    :disabled="readonly"
    :hide-details="select"
    :model-value="modelValue"
    :items="data.items"
    :error-messages="errorMessage ? [errorMessage] : undefined"
    :rules="rules"
    :label="field.label"
    :item-title="field?.title || 'name'"
    :item-value="field?.id || 'id'"
    :multiple="field.multiple"
    :chips="field.multiple"
    return-object
    deletable-chips
    :clearable="clearable && !readonly"
    @update:model-value="updateValue" >
    <template v-slot:item="{ props, on, item }">
      <v-list-item 
        v-bind="props" two-line :title="autocompleteTitle(item.raw)" :subtitle="autocompleteSubtitle(item.raw)" />
    </template>    
    </v-select>
  
  <v-autocomplete
    v-if="field.type === 'autocomplete'"
    :id="`field-${field.key}`"
    :menu-props="{ width: '273px' }"
    :disabled="readonly"
    :hide-details="select"
    :return-object="field.return_object"
    :model-value="modelValue"
    persistent-hint
    :hint='typeof field.hint === "function" ? field.hint(item) : field.hint'
    :items="data.items"
    :rules="rules"
    :multiple="field.multiple"
    :chips="field.multiple"
    :small-chips="field.multiple"
    auto-select-first
    closable-chips
    density="compact"
    no-filter
    :error-messages="errorMessage ? [errorMessage] : undefined"
    :loading="data.loading"
    :label="field.label"
    v-model:focused="data.focus"
    autocomplete="off"
    v-model:search="data.autocomplete_search"
    :placeholder="field.placeholder || ( field.multiple ? `Search in ${field.label}...` : `Choose a ${field.label}`)"
    :class="select ? 'ma-0 pa-0' : ''"
    hide-no-data
    :item-title="field.title || 'name'"
    :item-value="field.id || 'id'"
    :prepend-inner-icon="!select ? 'mdi-database-search' : ''"
    md4
    :clearable="clearable && !readonly"
    @update:model-value="updateValue"
  >
    <template v-slot:item="{ props, on, item }">
      <v-list-item 
        v-bind="props" two-line :title="autocompleteTitle(item.raw)" :subtitle="autocompleteSubtitle(item.raw)" />
    </template>
  </v-autocomplete>

<!--   
  <v-combobox
  v-if="field.type === 'combobox'"
  :disabled="readonly"
  :multiple="field.multiple"
  :chips="field.multiple"
  :small-chips="field.multiple"
  deletable-chips
  :value="value"
  :items="!field.manual ? items : []"
  :rules="!readonly ? rules : []"
  :loading="loading"
  :label="field.text"
  :placeholder="`Choose a ${field.text}...`"
  color="green"
  hide-no-data
  :prepend-icon="!field.manual ? 'mdi-database-search' : ''"
  md4
  clearable
  @change="updateValue">
  <template v-slot:item="{ item }">
    <v-list-item-content two-line>
      <v-list-item-title v-text="autocompleteTitle(item)" />
      <v-list-item-subtitle v-text="autocompleteSubtitle(item)" />
    </v-list-item-content>
  </template>
</v-combobox> -->


<v-file-input
  v-if="field.type === 'file'"
  :id="`field-${field.key}`"
  @update:model-value='updateValue'
  :hint='field.hint'
  persistent-hint
  prepend-inner-icon="mdi-attachment"
  prepend-icon
  :disabled='readonly'
  :accept="field.accept || 'application/pdf'"
  :label="field.label"
  :rules='rules'
/>

<!-- <v-checkbox
  v-if="field.type === 'check'"
  :disabled="readonly"
  :label="field.title"
  :true-value="1"
  :false-value="0"
  :model-value='modelValue'
  full-width
  required
  @update:model-value="updateValue"
/> -->

<v-switch
  v-if="field.type === 'switch' || 'check' === field.type"
  :id="`field-${field.key}`"
  :disabled="readonly"
  :label="field.label"
  :true-value="1"
  :false-value="0"
  persistent-hint
  :rules="rules"
  :hint='field.hint'
  :model-value='modelValue'
  full-width
  inset
  @update:model-value="updateValue"
/>

<v-textarea
  v-if="field.type === 'textarea'"
  :id="`field-${field.key}`"
  :model-value="modelValue"
  :rows="3"
  :rules="!readonly ? rules : []"
  persistent-hint
  :hint='typeof field.hint === "function" ? field.hint(item) : field.hint'
  :label="field.label"
  :disabled="readonly"
  :clearable="clearable && !readonly"
  @update:model-value="updateValue"
/>


<slot v-if="field.type === 'component'" :id="`field-${field.key}`" />

<!-- <v-date-picker
      style="position: absolute; z-index: 1000000; display: block;"
      v-if="field.type === 'datetime'"
      :value="modelValue"
      @update:model-value="updateValue"
      >
			<template #default="{ showPopover, hidePopover }">
				<v-text-field
					readonly
					:model-value="modelValue"
					:label="field.label"
					@click="showPopover"
					prepend-inner-icon="mdi-calendar"
					:rules="rules"
				/>
			</template>
		</v-date-picker> -->

  
  <!-- <v-menu
  v-if="field.type === 'datetime'"
  ref="menu_datePicked"
  v-model="data.menu_datePicker"
  :disabled="readonly"
  :close-on-content-click="false"
  :rules="rules"
  transition="scale-transition"
  offset-y
  max-width="290px"
  min-width="290px"
  >
  <template v-slot:activator="{ on, attrs }">
    <v-text-field
    :value="modelValue"
    :rules="!readonly ? rules : []"
    :label="field.text"
    v-bind="attrs"
    :clearable='!readonly'
    :disabled='readonly'
    readonly
    v-on="on"
    /> -->
    <!-- @click:clear='dateChange()' -->
  <!-- </template>
  <v-date-picker
  :disabled="readonly"
  :rules="rules"
  dark
  :value='modelValue'
  no-title
  /> -->
  <!-- :max="maxDate()"
  :min="minDate()"
  @input="dateChange" -->
<!-- </v-menu> -->

<!-- <template slot="item" slot-scope="{ item }" >
  <v-list-tile-content>
    <v-list-tile-title>{{item.name}}</v-list-tile-title>
  </v-list-tile-content>
</template> -->
<!-- </v-autocomplete> -->

</v-col>
</template>