import { default as config_accountmcYDXusAFfMeta } from "/usr/src/app/pages/accounts/config_account.js?macro=true";
import { default as indexjwGqPA3HyLMeta } from "/usr/src/app/pages/accounts/index.vue?macro=true";
import { default as control_45centerK341x81TM5Meta } from "/usr/src/app/pages/admin/control-center.vue?macro=true";
import { default as confirm_45reset_45passXW2db2PY1zMeta } from "/usr/src/app/pages/confirm-reset-pass.vue?macro=true";
import { default as config_logvHTsqp0vA1Meta } from "/usr/src/app/pages/dashboard/config_log.js?macro=true";
import { default as indexRKWbHmLmj6Meta } from "/usr/src/app/pages/dashboard/index.vue?macro=true";
import { default as config_data_dictionarygNNGTRckU6Meta } from "/usr/src/app/pages/dd/config_data_dictionary.js?macro=true";
import { default as indexsuUfAxExLFMeta } from "/usr/src/app/pages/dd/index.vue?macro=true";
import { default as config_accountc3uzxr2XCjMeta } from "/usr/src/app/pages/example/config_account.js?macro=true";
import { default as indexYiyZ70TQWpMeta } from "/usr/src/app/pages/example/index.vue?macro=true";
import { default as config_accountsn5ZWUTleoMeta } from "/usr/src/app/pages/example2/config_account.js?macro=true";
import { default as indexaICe1A8NLUMeta } from "/usr/src/app/pages/example2/index.vue?macro=true";
import { default as _91_91node_id_93_93iAoRHVzaaZMeta } from "/usr/src/app/pages/explorer/[[node_id]].vue?macro=true";
import { default as data1oNMuKV7TXMeta } from "/usr/src/app/pages/explorer/data.js?macro=true";
import { default as config_treatmentHy3PSM6v3oMeta } from "/usr/src/app/pages/ip/config_treatment.js?macro=true";
import { default as indexCVXpcugqOIMeta } from "/usr/src/app/pages/ip/index.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as index7BcxWVTd2NMeta } from "/usr/src/app/pages/metric/index.vue?macro=true";
import { default as indexSTgaE7HOJmMeta } from "/usr/src/app/pages/milestone_setup/index.vue?macro=true";
import { default as config_organization1jIpaTRWpFMeta } from "/usr/src/app/pages/organizations/config_organization.js?macro=true";
import { default as indexR6lmOcaBZIMeta } from "/usr/src/app/pages/organizations/index.vue?macro=true";
import { default as confighykWPt5VnYMeta } from "/usr/src/app/pages/pending/config.js?macro=true";
import { default as data94OTc4rBwHMeta } from "/usr/src/app/pages/pending/data.js?macro=true";
import { default as indexfhTNx8toAFMeta } from "/usr/src/app/pages/pending/index.vue?macro=true";
import { default as indexC3dyDsUAPYMeta } from "/usr/src/app/pages/permission/index.vue?macro=true";
import { default as config_personnelcav1KJj857Meta } from "/usr/src/app/pages/personnel/config_personnel.js?macro=true";
import { default as indexe36qK1iDyzMeta } from "/usr/src/app/pages/personnel/index.vue?macro=true";
import { default as index77UxeWDrkFMeta } from "/usr/src/app/pages/profile/index.vue?macro=true";
import { default as config_protocol_has_account_has_organization1QnwzPm74qMeta } from "/usr/src/app/pages/protocol_has_account_has_organization/config_protocol_has_account_has_organization.js?macro=true";
import { default as index11va8J1B8oMeta } from "/usr/src/app/pages/protocol_has_account_has_organization/index.vue?macro=true";
import { default as config_protocol_has_accountrNUlEYTr5cMeta } from "/usr/src/app/pages/protocol_has_account/config_protocol_has_account.js?macro=true";
import { default as indexBfMAMH2JmhMeta } from "/usr/src/app/pages/protocol_has_account/index.vue?macro=true";
import { default as config_protocol_has_organizationaFznEA5UrtMeta } from "/usr/src/app/pages/protocol_has_organization/config_protocol_has_organization.js?macro=true";
import { default as indexrAHlB1TFmJMeta } from "/usr/src/app/pages/protocol_has_organization/index.vue?macro=true";
import { default as config_protocol_has_personnelmHgTSH5RdXMeta } from "/usr/src/app/pages/protocol_has_personnel/config_protocol_has_personnel.js?macro=true";
import { default as indexxRw4UWJdtEMeta } from "/usr/src/app/pages/protocol_has_personnel/index.vue?macro=true";
import { default as indexAQhC7B48EGMeta } from "/usr/src/app/pages/protocols/index.vue?macro=true";
import { default as dataNcpjjbPNqHMeta } from "/usr/src/app/pages/report/data.js?macro=true";
import { default as index7rxkBnYhsqMeta } from "/usr/src/app/pages/report/index.vue?macro=true";
import { default as reset_45passSLD7TndOczMeta } from "/usr/src/app/pages/reset-pass.vue?macro=true";
import { default as config_sitecYnaVefM1tMeta } from "/usr/src/app/pages/site/config_site.js?macro=true";
import { default as index2HxYmQ1qUYMeta } from "/usr/src/app/pages/site/index.vue?macro=true";
import { default as config_study_roleygWqasLzaEMeta } from "/usr/src/app/pages/study_role/config_study_role.js?macro=true";
import { default as indexgmpGHDnsg3Meta } from "/usr/src/app/pages/study_role/index.vue?macro=true";
import { default as config_task6X07MFVZC4Meta } from "/usr/src/app/pages/task/config_task.js?macro=true";
import { default as indexzLaDMKp3d9Meta } from "/usr/src/app/pages/task/index.vue?macro=true";
import { default as indexgRJ6H2ODyxMeta } from "/usr/src/app/pages/testflex/index.vue?macro=true";
import { default as indexxFWLtgoGfxMeta } from "/usr/src/app/pages/tree_permission/index.vue?macro=true";
export default [
  {
    name: config_accountmcYDXusAFfMeta?.name ?? "accounts-config_account",
    path: config_accountmcYDXusAFfMeta?.path ?? "/accounts/config_account",
    meta: config_accountmcYDXusAFfMeta || {},
    alias: config_accountmcYDXusAFfMeta?.alias || [],
    redirect: config_accountmcYDXusAFfMeta?.redirect,
    component: () => import("/usr/src/app/pages/accounts/config_account.js").then(m => m.default || m)
  },
  {
    name: indexjwGqPA3HyLMeta?.name ?? "accounts",
    path: indexjwGqPA3HyLMeta?.path ?? "/accounts",
    meta: indexjwGqPA3HyLMeta || {},
    alias: indexjwGqPA3HyLMeta?.alias || [],
    redirect: indexjwGqPA3HyLMeta?.redirect,
    component: () => import("/usr/src/app/pages/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: control_45centerK341x81TM5Meta?.name ?? "admin-control-center",
    path: control_45centerK341x81TM5Meta?.path ?? "/admin/control-center",
    meta: control_45centerK341x81TM5Meta || {},
    alias: control_45centerK341x81TM5Meta?.alias || [],
    redirect: control_45centerK341x81TM5Meta?.redirect,
    component: () => import("/usr/src/app/pages/admin/control-center.vue").then(m => m.default || m)
  },
  {
    name: confirm_45reset_45passXW2db2PY1zMeta?.name ?? "confirm-reset-pass",
    path: confirm_45reset_45passXW2db2PY1zMeta?.path ?? "/confirm-reset-pass",
    meta: confirm_45reset_45passXW2db2PY1zMeta || {},
    alias: confirm_45reset_45passXW2db2PY1zMeta?.alias || [],
    redirect: confirm_45reset_45passXW2db2PY1zMeta?.redirect,
    component: () => import("/usr/src/app/pages/confirm-reset-pass.vue").then(m => m.default || m)
  },
  {
    name: config_logvHTsqp0vA1Meta?.name ?? "dashboard-config_log",
    path: config_logvHTsqp0vA1Meta?.path ?? "/dashboard/config_log",
    meta: config_logvHTsqp0vA1Meta || {},
    alias: config_logvHTsqp0vA1Meta?.alias || [],
    redirect: config_logvHTsqp0vA1Meta?.redirect,
    component: () => import("/usr/src/app/pages/dashboard/config_log.js").then(m => m.default || m)
  },
  {
    name: indexRKWbHmLmj6Meta?.name ?? "dashboard",
    path: indexRKWbHmLmj6Meta?.path ?? "/dashboard",
    meta: indexRKWbHmLmj6Meta || {},
    alias: indexRKWbHmLmj6Meta?.alias || [],
    redirect: indexRKWbHmLmj6Meta?.redirect,
    component: () => import("/usr/src/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: config_data_dictionarygNNGTRckU6Meta?.name ?? "dd-config_data_dictionary",
    path: config_data_dictionarygNNGTRckU6Meta?.path ?? "/dd/config_data_dictionary",
    meta: config_data_dictionarygNNGTRckU6Meta || {},
    alias: config_data_dictionarygNNGTRckU6Meta?.alias || [],
    redirect: config_data_dictionarygNNGTRckU6Meta?.redirect,
    component: () => import("/usr/src/app/pages/dd/config_data_dictionary.js").then(m => m.default || m)
  },
  {
    name: indexsuUfAxExLFMeta?.name ?? "dd",
    path: indexsuUfAxExLFMeta?.path ?? "/dd",
    meta: indexsuUfAxExLFMeta || {},
    alias: indexsuUfAxExLFMeta?.alias || [],
    redirect: indexsuUfAxExLFMeta?.redirect,
    component: () => import("/usr/src/app/pages/dd/index.vue").then(m => m.default || m)
  },
  {
    name: config_accountc3uzxr2XCjMeta?.name ?? "example-config_account",
    path: config_accountc3uzxr2XCjMeta?.path ?? "/example/config_account",
    meta: config_accountc3uzxr2XCjMeta || {},
    alias: config_accountc3uzxr2XCjMeta?.alias || [],
    redirect: config_accountc3uzxr2XCjMeta?.redirect,
    component: () => import("/usr/src/app/pages/example/config_account.js").then(m => m.default || m)
  },
  {
    name: indexYiyZ70TQWpMeta?.name ?? "example",
    path: indexYiyZ70TQWpMeta?.path ?? "/example",
    meta: indexYiyZ70TQWpMeta || {},
    alias: indexYiyZ70TQWpMeta?.alias || [],
    redirect: indexYiyZ70TQWpMeta?.redirect,
    component: () => import("/usr/src/app/pages/example/index.vue").then(m => m.default || m)
  },
  {
    name: config_accountsn5ZWUTleoMeta?.name ?? "example2-config_account",
    path: config_accountsn5ZWUTleoMeta?.path ?? "/example2/config_account",
    meta: config_accountsn5ZWUTleoMeta || {},
    alias: config_accountsn5ZWUTleoMeta?.alias || [],
    redirect: config_accountsn5ZWUTleoMeta?.redirect,
    component: () => import("/usr/src/app/pages/example2/config_account.js").then(m => m.default || m)
  },
  {
    name: indexaICe1A8NLUMeta?.name ?? "example2",
    path: indexaICe1A8NLUMeta?.path ?? "/example2",
    meta: indexaICe1A8NLUMeta || {},
    alias: indexaICe1A8NLUMeta?.alias || [],
    redirect: indexaICe1A8NLUMeta?.redirect,
    component: () => import("/usr/src/app/pages/example2/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91node_id_93_93iAoRHVzaaZMeta?.name ?? "explorer-node_id",
    path: _91_91node_id_93_93iAoRHVzaaZMeta?.path ?? "/explorer/:node_id?",
    meta: _91_91node_id_93_93iAoRHVzaaZMeta || {},
    alias: _91_91node_id_93_93iAoRHVzaaZMeta?.alias || [],
    redirect: _91_91node_id_93_93iAoRHVzaaZMeta?.redirect,
    component: () => import("/usr/src/app/pages/explorer/[[node_id]].vue").then(m => m.default || m)
  },
  {
    name: data1oNMuKV7TXMeta?.name ?? "explorer-data",
    path: data1oNMuKV7TXMeta?.path ?? "/explorer/data",
    meta: data1oNMuKV7TXMeta || {},
    alias: data1oNMuKV7TXMeta?.alias || [],
    redirect: data1oNMuKV7TXMeta?.redirect,
    component: () => import("/usr/src/app/pages/explorer/data.js").then(m => m.default || m)
  },
  {
    name: config_treatmentHy3PSM6v3oMeta?.name ?? "ip-config_treatment",
    path: config_treatmentHy3PSM6v3oMeta?.path ?? "/ip/config_treatment",
    meta: config_treatmentHy3PSM6v3oMeta || {},
    alias: config_treatmentHy3PSM6v3oMeta?.alias || [],
    redirect: config_treatmentHy3PSM6v3oMeta?.redirect,
    component: () => import("/usr/src/app/pages/ip/config_treatment.js").then(m => m.default || m)
  },
  {
    name: indexCVXpcugqOIMeta?.name ?? "ip",
    path: indexCVXpcugqOIMeta?.path ?? "/ip",
    meta: indexCVXpcugqOIMeta || {},
    alias: indexCVXpcugqOIMeta?.alias || [],
    redirect: indexCVXpcugqOIMeta?.redirect,
    component: () => import("/usr/src/app/pages/ip/index.vue").then(m => m.default || m)
  },
  {
    name: loginRGcvZC480vMeta?.name ?? "login",
    path: loginRGcvZC480vMeta?.path ?? "/login",
    meta: loginRGcvZC480vMeta || {},
    alias: loginRGcvZC480vMeta?.alias || [],
    redirect: loginRGcvZC480vMeta?.redirect,
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: index7BcxWVTd2NMeta?.name ?? "metric",
    path: index7BcxWVTd2NMeta?.path ?? "/metric",
    meta: index7BcxWVTd2NMeta || {},
    alias: index7BcxWVTd2NMeta?.alias || [],
    redirect: index7BcxWVTd2NMeta?.redirect,
    component: () => import("/usr/src/app/pages/metric/index.vue").then(m => m.default || m)
  },
  {
    name: indexSTgaE7HOJmMeta?.name ?? "milestone_setup",
    path: indexSTgaE7HOJmMeta?.path ?? "/milestone_setup",
    meta: indexSTgaE7HOJmMeta || {},
    alias: indexSTgaE7HOJmMeta?.alias || [],
    redirect: indexSTgaE7HOJmMeta?.redirect,
    component: () => import("/usr/src/app/pages/milestone_setup/index.vue").then(m => m.default || m)
  },
  {
    name: config_organization1jIpaTRWpFMeta?.name ?? "organizations-config_organization",
    path: config_organization1jIpaTRWpFMeta?.path ?? "/organizations/config_organization",
    meta: config_organization1jIpaTRWpFMeta || {},
    alias: config_organization1jIpaTRWpFMeta?.alias || [],
    redirect: config_organization1jIpaTRWpFMeta?.redirect,
    component: () => import("/usr/src/app/pages/organizations/config_organization.js").then(m => m.default || m)
  },
  {
    name: indexR6lmOcaBZIMeta?.name ?? "organizations",
    path: indexR6lmOcaBZIMeta?.path ?? "/organizations",
    meta: indexR6lmOcaBZIMeta || {},
    alias: indexR6lmOcaBZIMeta?.alias || [],
    redirect: indexR6lmOcaBZIMeta?.redirect,
    component: () => import("/usr/src/app/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: confighykWPt5VnYMeta?.name ?? "pending-config",
    path: confighykWPt5VnYMeta?.path ?? "/pending/config",
    meta: confighykWPt5VnYMeta || {},
    alias: confighykWPt5VnYMeta?.alias || [],
    redirect: confighykWPt5VnYMeta?.redirect,
    component: () => import("/usr/src/app/pages/pending/config.js").then(m => m.default || m)
  },
  {
    name: data94OTc4rBwHMeta?.name ?? "pending-data",
    path: data94OTc4rBwHMeta?.path ?? "/pending/data",
    meta: data94OTc4rBwHMeta || {},
    alias: data94OTc4rBwHMeta?.alias || [],
    redirect: data94OTc4rBwHMeta?.redirect,
    component: () => import("/usr/src/app/pages/pending/data.js").then(m => m.default || m)
  },
  {
    name: indexfhTNx8toAFMeta?.name ?? "pending",
    path: indexfhTNx8toAFMeta?.path ?? "/pending",
    meta: indexfhTNx8toAFMeta || {},
    alias: indexfhTNx8toAFMeta?.alias || [],
    redirect: indexfhTNx8toAFMeta?.redirect,
    component: () => import("/usr/src/app/pages/pending/index.vue").then(m => m.default || m)
  },
  {
    name: indexC3dyDsUAPYMeta?.name ?? "permission",
    path: indexC3dyDsUAPYMeta?.path ?? "/permission",
    meta: indexC3dyDsUAPYMeta || {},
    alias: indexC3dyDsUAPYMeta?.alias || [],
    redirect: indexC3dyDsUAPYMeta?.redirect,
    component: () => import("/usr/src/app/pages/permission/index.vue").then(m => m.default || m)
  },
  {
    name: config_personnelcav1KJj857Meta?.name ?? "personnel-config_personnel",
    path: config_personnelcav1KJj857Meta?.path ?? "/personnel/config_personnel",
    meta: config_personnelcav1KJj857Meta || {},
    alias: config_personnelcav1KJj857Meta?.alias || [],
    redirect: config_personnelcav1KJj857Meta?.redirect,
    component: () => import("/usr/src/app/pages/personnel/config_personnel.js").then(m => m.default || m)
  },
  {
    name: indexe36qK1iDyzMeta?.name ?? "personnel",
    path: indexe36qK1iDyzMeta?.path ?? "/personnel",
    meta: indexe36qK1iDyzMeta || {},
    alias: indexe36qK1iDyzMeta?.alias || [],
    redirect: indexe36qK1iDyzMeta?.redirect,
    component: () => import("/usr/src/app/pages/personnel/index.vue").then(m => m.default || m)
  },
  {
    name: index77UxeWDrkFMeta?.name ?? "profile",
    path: index77UxeWDrkFMeta?.path ?? "/profile",
    meta: index77UxeWDrkFMeta || {},
    alias: index77UxeWDrkFMeta?.alias || [],
    redirect: index77UxeWDrkFMeta?.redirect,
    component: () => import("/usr/src/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: config_protocol_has_account_has_organization1QnwzPm74qMeta?.name ?? "protocol_has_account_has_organization-config_protocol_has_account_has_organization",
    path: config_protocol_has_account_has_organization1QnwzPm74qMeta?.path ?? "/protocol_has_account_has_organization/config_protocol_has_account_has_organization",
    meta: config_protocol_has_account_has_organization1QnwzPm74qMeta || {},
    alias: config_protocol_has_account_has_organization1QnwzPm74qMeta?.alias || [],
    redirect: config_protocol_has_account_has_organization1QnwzPm74qMeta?.redirect,
    component: () => import("/usr/src/app/pages/protocol_has_account_has_organization/config_protocol_has_account_has_organization.js").then(m => m.default || m)
  },
  {
    name: index11va8J1B8oMeta?.name ?? "protocol_has_account_has_organization",
    path: index11va8J1B8oMeta?.path ?? "/protocol_has_account_has_organization",
    meta: index11va8J1B8oMeta || {},
    alias: index11va8J1B8oMeta?.alias || [],
    redirect: index11va8J1B8oMeta?.redirect,
    component: () => import("/usr/src/app/pages/protocol_has_account_has_organization/index.vue").then(m => m.default || m)
  },
  {
    name: config_protocol_has_accountrNUlEYTr5cMeta?.name ?? "protocol_has_account-config_protocol_has_account",
    path: config_protocol_has_accountrNUlEYTr5cMeta?.path ?? "/protocol_has_account/config_protocol_has_account",
    meta: config_protocol_has_accountrNUlEYTr5cMeta || {},
    alias: config_protocol_has_accountrNUlEYTr5cMeta?.alias || [],
    redirect: config_protocol_has_accountrNUlEYTr5cMeta?.redirect,
    component: () => import("/usr/src/app/pages/protocol_has_account/config_protocol_has_account.js").then(m => m.default || m)
  },
  {
    name: indexBfMAMH2JmhMeta?.name ?? "protocol_has_account",
    path: indexBfMAMH2JmhMeta?.path ?? "/protocol_has_account",
    meta: indexBfMAMH2JmhMeta || {},
    alias: indexBfMAMH2JmhMeta?.alias || [],
    redirect: indexBfMAMH2JmhMeta?.redirect,
    component: () => import("/usr/src/app/pages/protocol_has_account/index.vue").then(m => m.default || m)
  },
  {
    name: config_protocol_has_organizationaFznEA5UrtMeta?.name ?? "protocol_has_organization-config_protocol_has_organization",
    path: config_protocol_has_organizationaFznEA5UrtMeta?.path ?? "/protocol_has_organization/config_protocol_has_organization",
    meta: config_protocol_has_organizationaFznEA5UrtMeta || {},
    alias: config_protocol_has_organizationaFznEA5UrtMeta?.alias || [],
    redirect: config_protocol_has_organizationaFznEA5UrtMeta?.redirect,
    component: () => import("/usr/src/app/pages/protocol_has_organization/config_protocol_has_organization.js").then(m => m.default || m)
  },
  {
    name: indexrAHlB1TFmJMeta?.name ?? "protocol_has_organization",
    path: indexrAHlB1TFmJMeta?.path ?? "/protocol_has_organization",
    meta: indexrAHlB1TFmJMeta || {},
    alias: indexrAHlB1TFmJMeta?.alias || [],
    redirect: indexrAHlB1TFmJMeta?.redirect,
    component: () => import("/usr/src/app/pages/protocol_has_organization/index.vue").then(m => m.default || m)
  },
  {
    name: config_protocol_has_personnelmHgTSH5RdXMeta?.name ?? "protocol_has_personnel-config_protocol_has_personnel",
    path: config_protocol_has_personnelmHgTSH5RdXMeta?.path ?? "/protocol_has_personnel/config_protocol_has_personnel",
    meta: config_protocol_has_personnelmHgTSH5RdXMeta || {},
    alias: config_protocol_has_personnelmHgTSH5RdXMeta?.alias || [],
    redirect: config_protocol_has_personnelmHgTSH5RdXMeta?.redirect,
    component: () => import("/usr/src/app/pages/protocol_has_personnel/config_protocol_has_personnel.js").then(m => m.default || m)
  },
  {
    name: indexxRw4UWJdtEMeta?.name ?? "protocol_has_personnel",
    path: indexxRw4UWJdtEMeta?.path ?? "/protocol_has_personnel",
    meta: indexxRw4UWJdtEMeta || {},
    alias: indexxRw4UWJdtEMeta?.alias || [],
    redirect: indexxRw4UWJdtEMeta?.redirect,
    component: () => import("/usr/src/app/pages/protocol_has_personnel/index.vue").then(m => m.default || m)
  },
  {
    name: indexAQhC7B48EGMeta?.name ?? "protocols",
    path: indexAQhC7B48EGMeta?.path ?? "/protocols",
    meta: indexAQhC7B48EGMeta || {},
    alias: indexAQhC7B48EGMeta?.alias || [],
    redirect: indexAQhC7B48EGMeta?.redirect,
    component: () => import("/usr/src/app/pages/protocols/index.vue").then(m => m.default || m)
  },
  {
    name: dataNcpjjbPNqHMeta?.name ?? "report-data",
    path: dataNcpjjbPNqHMeta?.path ?? "/report/data",
    meta: dataNcpjjbPNqHMeta || {},
    alias: dataNcpjjbPNqHMeta?.alias || [],
    redirect: dataNcpjjbPNqHMeta?.redirect,
    component: () => import("/usr/src/app/pages/report/data.js").then(m => m.default || m)
  },
  {
    name: index7rxkBnYhsqMeta?.name ?? "report",
    path: index7rxkBnYhsqMeta?.path ?? "/report",
    meta: index7rxkBnYhsqMeta || {},
    alias: index7rxkBnYhsqMeta?.alias || [],
    redirect: index7rxkBnYhsqMeta?.redirect,
    component: () => import("/usr/src/app/pages/report/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45passSLD7TndOczMeta?.name ?? "reset-pass",
    path: reset_45passSLD7TndOczMeta?.path ?? "/reset-pass",
    meta: reset_45passSLD7TndOczMeta || {},
    alias: reset_45passSLD7TndOczMeta?.alias || [],
    redirect: reset_45passSLD7TndOczMeta?.redirect,
    component: () => import("/usr/src/app/pages/reset-pass.vue").then(m => m.default || m)
  },
  {
    name: config_sitecYnaVefM1tMeta?.name ?? "site-config_site",
    path: config_sitecYnaVefM1tMeta?.path ?? "/site/config_site",
    meta: config_sitecYnaVefM1tMeta || {},
    alias: config_sitecYnaVefM1tMeta?.alias || [],
    redirect: config_sitecYnaVefM1tMeta?.redirect,
    component: () => import("/usr/src/app/pages/site/config_site.js").then(m => m.default || m)
  },
  {
    name: index2HxYmQ1qUYMeta?.name ?? "site",
    path: index2HxYmQ1qUYMeta?.path ?? "/site",
    meta: index2HxYmQ1qUYMeta || {},
    alias: index2HxYmQ1qUYMeta?.alias || [],
    redirect: index2HxYmQ1qUYMeta?.redirect,
    component: () => import("/usr/src/app/pages/site/index.vue").then(m => m.default || m)
  },
  {
    name: config_study_roleygWqasLzaEMeta?.name ?? "study_role-config_study_role",
    path: config_study_roleygWqasLzaEMeta?.path ?? "/study_role/config_study_role",
    meta: config_study_roleygWqasLzaEMeta || {},
    alias: config_study_roleygWqasLzaEMeta?.alias || [],
    redirect: config_study_roleygWqasLzaEMeta?.redirect,
    component: () => import("/usr/src/app/pages/study_role/config_study_role.js").then(m => m.default || m)
  },
  {
    name: indexgmpGHDnsg3Meta?.name ?? "study_role",
    path: indexgmpGHDnsg3Meta?.path ?? "/study_role",
    meta: indexgmpGHDnsg3Meta || {},
    alias: indexgmpGHDnsg3Meta?.alias || [],
    redirect: indexgmpGHDnsg3Meta?.redirect,
    component: () => import("/usr/src/app/pages/study_role/index.vue").then(m => m.default || m)
  },
  {
    name: config_task6X07MFVZC4Meta?.name ?? "task-config_task",
    path: config_task6X07MFVZC4Meta?.path ?? "/task/config_task",
    meta: config_task6X07MFVZC4Meta || {},
    alias: config_task6X07MFVZC4Meta?.alias || [],
    redirect: config_task6X07MFVZC4Meta?.redirect,
    component: () => import("/usr/src/app/pages/task/config_task.js").then(m => m.default || m)
  },
  {
    name: indexzLaDMKp3d9Meta?.name ?? "task",
    path: indexzLaDMKp3d9Meta?.path ?? "/task",
    meta: indexzLaDMKp3d9Meta || {},
    alias: indexzLaDMKp3d9Meta?.alias || [],
    redirect: indexzLaDMKp3d9Meta?.redirect,
    component: () => import("/usr/src/app/pages/task/index.vue").then(m => m.default || m)
  },
  {
    name: indexgRJ6H2ODyxMeta?.name ?? "testflex",
    path: indexgRJ6H2ODyxMeta?.path ?? "/testflex",
    meta: indexgRJ6H2ODyxMeta || {},
    alias: indexgRJ6H2ODyxMeta?.alias || [],
    redirect: indexgRJ6H2ODyxMeta?.redirect,
    component: () => import("/usr/src/app/pages/testflex/index.vue").then(m => m.default || m)
  },
  {
    name: indexxFWLtgoGfxMeta?.name ?? "tree_permission",
    path: indexxFWLtgoGfxMeta?.path ?? "/tree_permission",
    meta: indexxFWLtgoGfxMeta || {},
    alias: indexxFWLtgoGfxMeta?.alias || [],
    redirect: indexxFWLtgoGfxMeta?.redirect,
    component: () => import("/usr/src/app/pages/tree_permission/index.vue").then(m => m.default || m)
  }
]