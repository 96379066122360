export default {
    "entity": "data_dictionary",
    "title" : "Data dictionary",
    "headers": [

        // {
        //     "key": "parent_id",
        //     "reference" : "data_dictionary",
        //     // title: 'parent.name',
        //     "label": "Parent name",
        //     render: (item) => item.parent.name,
        //     "type": "autocomplete",
        //     "table": {
        //         filter: (search, item) => ({ 'parent.name': `%${search}%`}),
        //         "sortable": true
        //     },
        //     "subtitle": "name",
        //     // "visible": false,
        //     "readonly" : true
        // },
        
        // {
        //     "key": "name",
        //     "validators": [
        //         "required"
        //     ],
        // },

        {
            "key": "path",
            render: (item) => item.path.replaceAll('|', ' > '),
            filter: (search, item) => ({"path" : `%${search}%`})
        },


        {
            "key": "symlink",
            render: (item) => item.symlink_to && item.symlink.path.replaceAll('|', ' > '),
            filter: (search, item) => ({"symlink.path" : `%${search}%`})
        },

        // {
        //     "key": "expires",
        //     "label": "Expires",
        //     "type": "check",
        //     "noSearch":true
        // },


        // {
        //     "key": "days_to_expired",
        //     "label": "Day to expired",
        //     "type": "number",
        //     "noSearch":true
        // },

        {
            width: 50,
            "key": "is_file",
            label: 'Is file',
            "type": "check"
        },

        
       
    ],
    "actions": [
        //"upsert",
        // "remove"
       // "edit_custom"
       {
        name: 'saveDaysToExpired',
        icon: 'mdi-pencil',
        text: 'Edit Day Expired',
        color: (item) => { return item.expires ? 'success' : 'primary'},
        visible: (item) => {
            return true 
        }
    },
    ]
}