import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0_mitt_oROMKtszZd from "/usr/src/app/plugins/0.mitt.js";
import _0_serviceConfig_eYHXhbW4iz from "/usr/src/app/plugins/0.serviceConfig.js";
import _0_vuetify_Bq6id80yBZ from "/usr/src/app/plugins/0.vuetify.js";
import _1_dialog_xykKIlBKot from "/usr/src/app/plugins/1.dialog.js";
import _1_validators_vw3oFZd1Tk from "/usr/src/app/plugins/1.validators.js";
import _10_formatter_GEPIklYcXn from "/usr/src/app/plugins/10.formatter.js";
import _11_jsonpretty_DbAPZgwlKL from "/usr/src/app/plugins/11.jsonpretty.js";
import _2_permissions_6p0EWDRXcw from "/usr/src/app/plugins/2.permissions.js";
import _4_pdfviewer_kPvLM3CTQ3 from "/usr/src/app/plugins/4.pdfviewer.js";
import _5_tree_YMGvANy4H3 from "/usr/src/app/plugins/5.tree.js";
import _7_highlight_uKsUdpC8hL from "/usr/src/app/plugins/7.highlight.js";
import _8_time_qnvr9pW0tR from "/usr/src/app/plugins/8.time.js";
import _9_download_6qLcZl6AiN from "/usr/src/app/plugins/9.download.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  _0_mitt_oROMKtszZd,
  _0_serviceConfig_eYHXhbW4iz,
  _0_vuetify_Bq6id80yBZ,
  _1_dialog_xykKIlBKot,
  _1_validators_vw3oFZd1Tk,
  _10_formatter_GEPIklYcXn,
  _11_jsonpretty_DbAPZgwlKL,
  _2_permissions_6p0EWDRXcw,
  _4_pdfviewer_kPvLM3CTQ3,
  _5_tree_YMGvANy4H3,
  _7_highlight_uKsUdpC8hL,
  _8_time_qnvr9pW0tR,
  _9_download_6qLcZl6AiN
]